<template>
   <div class="container">
    <el-container class="wrap">
      <el-main class="mains">
        <div class="main_content">
            <img src="../assets/image/success.png" alt="">
            <p class="font-20 text-bold m-b-50">模考已完成</p>
            <p class="score m-b-10">{{start_score}} - {{end_score}}</p>
            <p class="font-16 m-b-70">预估得分</p>
            <div class="confirm" @click="goHome()">确定</div>
        </div>
      </el-main>
    </el-container>
      
     
   </div>
</template>
<script>
export default {
   data() {
      return {
        log_detail_id: '',
        start_score: 0,
        end_score: 0,
      }
   },
   created() {
     this.log_detail_id = this.$route.query.log_detail_id;
     this.getForecastNum()
   },
   methods: {
      goHome() {
        this.$router.push('/index')
      },
      getForecastNum() {
        this.$api.getForecastNum({
          log_detail_id: this.log_detail_id
        }).then(res => {
          if(res.code == 1) {
            this.start_score = res.data.start_score;
            this.end_score = res.data.end_score;
          }
        })
      },
   }
}
</script>
<style  lang="scss" scoped>
.container,.wrap {
  width: 100%;
  height: 100vh;
  background: #F6F7FB;
}
.mains {
  height: calc(100% - 70px);
  padding-top: 62px;
  box-sizing: border-box;
}

.main_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 2px;
  padding: 100px 0;
  img {
    width: 148px;
    height: 156px;
  }
  .score {
    display: inline-block;
    padding: 0 24px;
    background: #F6F7FB;
    font-size: 30px; 
    font-weight: bold;
    color: $color-title2;
  }
  .confirm {
    width: 110px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border-radius: 2px;
    background: $color-title2;
    color: #fff;
    font-size: 14px;
  }
}
</style>
